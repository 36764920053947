import React, { useEffect, useState } from "react"
import axios from "axios"
const ReportFormChennaiManagedOffice = () => {
  const [SuccessMessage, setSuccessMessage] = useState("hide")
  const [ErrorMessage, setErrorMessage] = useState("")
  const [Name, setName] = useState("")
  const [Email, setEmail] = useState("")
  const [Phone, setPhone] = useState("")
  const [organization, setOrganization] = useState("")
  const [Message, setMessage] = useState("")
  const [Button, SetButton] = useState(false)
  const [NameError, setNameError] = useState("")
  const [EmailError, setEmailError] = useState("")
  const [PhoneError, setPhoneError] = useState("")
  const [organizationsError, setorganizationsError] = useState("")
  const [referrerLink, setreferrerLink] = useState("")
  const [MessageError, setMessageError] = useState("")
  useEffect(() => {
    if (document.referrer && document.referrer != "") {
      // console.log("Thanks for visiting this site from " + document.referrer)
      setreferrerLink(document.referrer)
    } else {
      // console.log("Direct Visitor")
      setreferrerLink("")
    }
  })
  function validateEmail(email) {
    // Regular expression to check the email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    // Check if the email format is valid
    if (!emailRegex.test(email)) {
      return false
    }

    return true
  }
  const handleSubmit = e => {
    e.preventDefault()

    if (Name !== "" && Email !== "" && Phone !== "") {
      setErrorMessage("")
      if (Name.length <= 2) {
        setNameError("Enter Vaild Name")
      } else {
        setNameError("")
      }
      if (!validateEmail(Email)) {
        setEmailError("Please Enter Vaild Email Address")
      } else {
        setEmailError("")
      }
      if (!Phone.match("[0-9]{10}")) {
        setPhoneError("Please Enter Vaild Mobile No")
      } else {
        setPhoneError("")
      }
      if (organization.length <= 2) {
        setorganizationsError("Please Enter Vaild Organization Name")
      } else {
        setorganizationsError("")
      }
      if (
        Name.length >= 2 &&
        validateEmail(Email) &&
        Phone.match("[0-9]{10}") &&
        organization.length >= 2
      ) {
        axios
          .post(
            `https://gofloaters.firebaseapp.com/website/chennaiReportForm`,
            {
              name: Name,
              email: Email,
              phone: "+91" + Phone,
              organization: organization,
              referrerLink: referrerLink,
            }
          )
          .then(res => {
            setSuccessMessage("show")
            setName("")
            setEmail("")
            setPhone("")
            setOrganization("")
            SetButton(false)
          })
      }
    } else {
      setNameError("Enter Vaild Name")
      setEmailError("Please enter vaild email address")
      setPhoneError("Please enter vaild mobile no")
      setorganizationsError("Please enter vaild organization")
    }
  }
  // useEffect(() => {
  //   if (
  //     Name.length > 2 &&
  //     Phone.match("[0-9]") &&
  //     Phone.length >= 10 &&
  //     validateEmail(Email)
  //   ) {
  //     SetButton(false)
  //   } else {
  //     SetButton(true)
  //   }
  // })
  return (
    <div className="row">
      <div className="col-md-7">
        <div style={{ padding: "5px 15px" }}>
          <br></br>
          <h3 style={{ fontWeight: "bold" }} className="section-header-title">
            The coworking industry in Chennai isn't the same anymore
          </h3>
          <p>
            In the recent times, Chennai has rigorously evolved and enhanced to
            adapt to new trends. From the massive coworking surge during COVID
            times to widespread expansion of the city to upgrade office spaces,
            requirements from organizations and individuals have changed
            swiftly. <br />
            <br />
            This comprehensive report addresses how the shift has taken place
            and some key trends to note. <br />
            <br />
            If you're exploring office spaces or transitioning from a
            remote/hybrid setup to a regular office environment, this report is
            ideal for you. <br />
            <br />
            <b>What's inside?</b>
            <br />
            <ul style={{ fontWeight: "bold" }} className="hybridList">
              <li>
                <span>An industry overview</span>
              </li>
              <li>
                <span>Key players & trends to make note of</span>
              </li>
              <li>
                <span>Area-wise cost breakdown</span>
              </li>
              ... and more
            </ul>
            With this report, you can take a balanced decision towards
            your office needs.
          </p>
        </div>
      </div>
      <div className="col-md-5 teamForm ">
        <div className="hydridForm">
          {SuccessMessage === "hide" ? (
            <div id="signup" className="section-header-title">
              <h4 className="text-center">Download the insightful report </h4>
              <h5 className="text-center">It's free!</h5>
              <form>
                <div className="form-group">
                  <label htmlFor="Name">
                    Your Name *:{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {NameError}
                    </span>
                  </label>
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    value={Name}
                    onChange={e => {
                      setName(e.currentTarget.value)
                      setErrorMessage("")
                    }}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="email">
                    Email Address *:{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {EmailError}
                    </span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    className="form-control"
                    value={Email}
                    onChange={e => {
                      setEmail(e.currentTarget.value)
                      setErrorMessage("")
                    }}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Phone">
                    Phone *:{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {PhoneError}
                    </span>
                  </label>
                  <input
                    type="text"
                    name="phone"
                    className="form-control"
                    value={Phone}
                    onChange={e => {
                      var phone = e.currentTarget.value
                      if (!phone.match("[0-9]{10}")) {
                        setPhone(e.currentTarget.value)
                      } else {
                        setErrorMessage("")
                        setPhone(e.currentTarget.value)
                      }
                    }}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="Name">
                    Your Organization *:{" "}
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {organizationsError}
                    </span>
                  </label>
                  <input
                    type="text"
                    name="organization"
                    className="form-control"
                    value={organization}
                    onChange={e => {
                      setOrganization(e.currentTarget.value)
                      setErrorMessage("")
                    }}
                    required
                  />
                </div>

                <button
                  onClick={handleSubmit}
                  disabled={Button}
                  className="btn btn-default"
                >
                  Download the Free Report
                </button>
              </form>

              {ErrorMessage === "" ? (
                ""
              ) : (
                <p style={{ color: "red", fontWeight: "bold" }}>
                  <br />

                  {ErrorMessage}
                </p>
              )}
            </div>
          ) : (
            <div>
              <p style={{ color: "red", fontWeight: "bold" }}>{ErrorMessage}</p>
              <div className={"message " + SuccessMessage}>
                Thank you for submitting your form.
                <br></br>
                <br /> You can download the report here 👇
                <br></br>
                <br></br>
                <a
                  className="teamGetStarted"
                  target="_blank"
                  href="https://assets.gofloaters.com/chennai-managed-offices-report/chennai-managed-offices-report.pdf"
                  download
                >
                  Chennai Managed Office Report
                </a>
                <br></br>
                <br />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReportFormChennaiManagedOffice
